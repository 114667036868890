@import "bootstrap/scss/bootstrap";


html {
    min-height: 100vh;
    //font-size: 1.1em;
}

body {
    min-height: 100vh;
    // background-color: #f1f1f1;
    display: flex;
}

a {
    text-decoration: none;
}

.hidden {
    display: none !important;
}

.btn-default {
    background-color: lightgray;
}

.container {
    max-width: 1140px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.main {
    flex: 1;
    padding-top: 30px;
    .connect-wallet {
        display: flex;
        justify-content: center;
        .btn-connect-wallet {
            font-size: 1.4rem;
        }
    }
    .wallet-connected {
        div {
            margin-bottom: 5px;
        }
    }
}

@include media-breakpoint-up(sm) {
    html {
        font-size: rfs-fluid-value(1.1em);
    }
}
